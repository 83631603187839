import React from 'react';
import NavBarre from "../component/NavBarre";
import Footer from "../component/Footer";
function ProfessionnelPage() {


    return (
        <div className="containerPagePro">
            <NavBarre/>
            <div className="pageContainer">

                <div className="contentProPage">
                    <h1 data-aos="fade" data-aos-duration="500"  data-aos-once="true" className="text-3xl textGreen"><strong>Pourquoi choisir ALC Paysages ?</strong></h1>
                    <p data-aos="fade" data-aos-duration="800"  data-aos-once="true" className="text-sm">
                    ALC Paysages est le partenaire idéal pour l'entretien de vos espaces extérieurs professionnels. Nous comprenons que l'apparence de vos locaux joue un rôle important dans l'image de votre entreprise. C’est pourquoi nous vous proposons des services d'entretien sur mesure qui vont bien au-delà du simple jardinage.
                    </p>

                    <p data-aos="fade" data-aos-duration="900"  data-aos-once="true" className="text-sm">
                    Que vous soyez un indépendant, un commerçant, que vous possédiez des bureaux, un magasin, un entrepôt, ou tout autre type de structure, nous savons à quel point il est essentiel de maintenir un environnement extérieur propre, bien entretenu et agréable. Un espace extérieur soigné est souvent la première impression que vous donnez à vos clients et collaborateurs, et chez ALC Paysages, nous mettons tout en œuvre pour que cette première impression soit toujours positive.
                    </p>

                    <p data-aos="fade" data-aos-duration="1200"  data-aos-once="true" className="text-sm">
                    Nous proposons une large gamme de services d'entretien, tels que la tonte régulière de pelouse, la taille des haies, le nettoyage des espaces verts, et bien d'autres prestations adaptées à vos besoins spécifiques. Chaque intervention est réalisée de manière professionnelle et avec une attention particulière aux détails, afin de garantir que votre environnement extérieur reste impeccable tout au long de l'année.
                    </p>

                    <p data-aos="fade" data-aos-duration="1500"  data-aos-once="true" className="text-sm">
                    Notre objectif est de créer un espace extérieur à la fois fonctionnel et esthétique, qui corresponde à vos attentes et à l’image que vous souhaitez projeter. Nous travaillons de manière flexible pour nous adapter à vos horaires et minimiser les interruptions dans vos activités professionnelles.
                    </p>

                    <p data-aos="fade" data-aos-duration="1700"  data-aos-once="true" className="text-sm">
                    Chez ALC Paysages, nous nous engageons à vous offrir des services de qualité, adaptés à la taille et à la spécificité de votre entreprise. Notre équipe est disponible, réactive et toujours prête à répondre à vos demandes. Nous comprenons que chaque entreprise est unique, c’est pourquoi nous proposons des solutions personnalisées pour que vos espaces extérieurs soient toujours bien entretenus, quel que soit le type de structure.
                    </p>
                    <p data-aos="fade" data-aos-duration="1700"  data-aos-once="true" className="text-sm">
                    Contactez-nous dès aujourd’hui pour discuter de vos besoins en entretien d’espaces verts. Nous serons ravis de vous accompagner dans l’entretien de vos jardins et espaces extérieurs, afin de créer un environnement agréable et professionnel pour vous et vos collaborateurs.                    </p>
                    <a  href="/contact" data-aos="fade-up"   data-aos-once="true" style={{width: "60%"}}>
                        <button  className="buttonSendForm" style={{width: "100%"}}>

                            Nous contacter
                   </button> </a>
            </div>


        </div>
            <Footer/>
        </div>
    );
}

export default ProfessionnelPage;
