import React, {useState, useEffect, useCallback} from 'react';
import logo from "../assets/icons/logo.png";
import icoFacebook from "../assets/icons/facebook.svg";
import icoInsta from "../assets/icons/instagram.svg";
import icoPhone from "../assets/icons/telephone.svg";
import icoFacebookGreen from "../assets/icons/facebookGreen.svg";
import icoInstaGreen from "../assets/icons/instagramGreen.svg";
import icoPhoneGreen from "../assets/icons/telephoneGreen.svg";
import BurgerButton from './BurgerButton';
import Menu from './Menu';
import instDark from "../assets/icons/instagramDark.svg"
import faceDark from "../assets/icons/facebookDark.svg"
import phoneDark from "../assets/icons/telephoneDark.svg"
import { useTheme} from "./ModeContext";

function NavBarre() {

    const { setTheme } = useTheme();

    const [srcInsta, setSrcInsta] = useState(icoInsta);
    const [srcFace, setSrcFace] = useState(icoFacebook);
    const [srcPhone, setSrcPhone] = useState(icoPhone);
    const [show, setShow] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);
    const [menuOpen, setMenuOpen] = useState(false);

    const [instaMode, setInstaMode] = useState(icoInsta);
    const [faceMode, setFaceMode] = useState(icoFacebook);
    const [phoneMode, setPhoneMode] = useState(icoPhone);


    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const closeMenu = () => {
        setMenuOpen(false);
    };

    const controlNavbar = useCallback(() => {
        if (typeof window !== 'undefined') {
            if (window.scrollY <= 0) {
                setShow(true);
            } else {
                if (window.scrollY > lastScrollY) {
                    setShow(false);
                } else {
                    setShow(true);
                }
                setLastScrollY(window.scrollY);
            }
        }
    }, [lastScrollY]);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('scroll', controlNavbar);

            return () => {
                window.removeEventListener('scroll', controlNavbar);
            };
        }
    }, [controlNavbar]);
    

    function changeMode() {
        if (document.body.style.backgroundColor === "rgb(33, 47, 69)") {
            document.body.style.backgroundColor = "white";
            document.getElementsByClassName("textNavTel")[0].style.color="black"
            document.body.style.color = "black";
            document.getElementsByClassName("navbar")[0].style.backgroundColor="white"
            document.getElementsByClassName("menu")[0].style.backgroundColor="white"

            let navbarLinks = document.querySelectorAll(".navbar-links a");
            navbarLinks.forEach(function(link) {
                link.style.color = "black";
            });

            let burgerLinks = document.querySelectorAll(".menu a");
            burgerLinks.forEach(function(link) {
                link.style.color = "black";
            });

            let burger = document.querySelectorAll(".line");
            burger.forEach(function(link) {
                link.style.backgroundColor = "black";
            });

            let ligneBg = document.querySelectorAll(".ligneCard");
            ligneBg.forEach(function(link) {
                link.style.backgroundColor = "black";
            });

            setInstaMode(icoInsta)
            setFaceMode(icoFacebook)
            setPhoneMode(icoPhone)

            setSrcInsta(icoInsta)
            setSrcFace(icoFacebook)
            setSrcPhone(icoPhone)

            setTheme("white")

            let circle = document.getElementsByClassName("circle")[0]
            circle.style.backgroundImage = `url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjAiIHdpZHRoPSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIj4KICAgIDxwYXRoIGZpbGw9IiNmZmYiCiAgICAgICAgZD0iTTkuMzA1IDEuNjY3VjMuNzVoMS4zODlWMS42NjdoLTEuMzl6bS00LjcwNyAxLjk1bC0uOTgyLjk4Mkw1LjA5IDYuMDcybC45ODItLjk4Mi0xLjQ3My0xLjQ3M3ptMTAuODAyIDBMMTMuOTI3IDUuMDlsLjk4Mi45ODIgMS40NzMtMS40NzMtLjk4Mi0uOTgyek0xMCA1LjEzOWE0Ljg3MiA0Ljg3MiAwIDAwLTQuODYyIDQuODZBNC44NzIgNC44NzIgMCAwMDEwIDE0Ljg2MiA0Ljg3MiA0Ljg3MiAwIDAwMTQuODYgMTAgNC44NzIgNC44NzIgMCAwMDEwIDUuMTM5em0wIDEuMzg5QTMuNDYyIDMuNDYyIDAgMDExMy40NzEgMTBhMy40NjIgMy40NjIgMCAwMS0zLjQ3MyAzLjQ3MkEzLjQ2MiAzLjQ2MiAwIDAxNi41MjcgMTAgMy40NjIgMy40NjIgMCAwMTEwIDYuNTI4ek0xLjY2NSA5LjMwNXYxLjM5aDIuMDgzdi0xLjM5SDEuNjY2em0xNC41ODMgMHYxLjM5aDIuMDg0di0xLjM5aC0yLjA4NHpNNS4wOSAxMy45MjhMMy42MTYgMTUuNGwuOTgyLjk4MiAxLjQ3My0xLjQ3My0uOTgyLS45ODJ6bTkuODIgMGwtLjk4Mi45ODIgMS40NzMgMS40NzMuOTgyLS45ODItMS40NzMtMS40NzN6TTkuMzA1IDE2LjI1djIuMDgzaDEuMzg5VjE2LjI1aC0xLjM5eiIgLz4KPC9zdmc+)`;
            circle.style.left = `0`;

            const elements = document.querySelectorAll('.listeReduc');
            elements.forEach(element => {
                element.style.listStyleImage = 'url("/static/media/flecheListe.bd289e318ebd17167a54.svg")';
            });

            localStorage.setItem("mode", "white");
        } else {

            document.body.style.backgroundColor = "#212f45";
            document.body.style.color = "white";
            document.getElementsByClassName("textNavTel")[0].style.color="white"
            document.getElementsByClassName("navbar")[0].style.backgroundColor="#212f45"
            document.getElementsByClassName("menu")[0].style.backgroundColor="#212f45"

            let burger = document.querySelectorAll(".line");
            burger.forEach(function(link) {
                link.style.backgroundColor = "white";
            });

            let navbarLinks = document.querySelectorAll(".navbar-links a");
            navbarLinks.forEach(function(link) {
                link.style.color = "white";
            });

            let burgerLinks = document.querySelectorAll(".menu a");
            burgerLinks.forEach(function(link) {
                link.style.color = "white";
            });

            setInstaMode(instDark)
            setFaceMode(faceDark)
            setPhoneMode(phoneDark)

            setSrcInsta(instDark)
            setSrcFace(faceDark)
            setSrcPhone(phoneDark)


            let circle = document.getElementsByClassName("circle")[0]
            circle.style.backgroundImage = `url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjAiIHdpZHRoPSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIj4KICAgIDxwYXRoIGZpbGw9IiNmZmYiCiAgICAgICAgZD0iTTQuMiAyLjVsLS43IDEuOC0xLjguNyAxLjguNy43IDEuOC42LTEuOEw2LjcgNWwtMS45LS43LS42LTEuOHptMTUgOC4zYTYuNyA2LjcgMCAxMS02LjYtNi42IDUuOCA1LjggMCAwMDYuNiA2LjZ6IiAvPgo8L3N2Zz4=)`;
            circle.style.left = `calc(100% - var(--circle-diameter))`;

            let ligneBg = document.querySelectorAll(".ligneCard");
            ligneBg.forEach(function(link) {
                link.style.backgroundColor = "white";
            });

            setTheme("dark")

            const elements = document.querySelectorAll('.listeReduc');
            elements.forEach(element => {
                element.style.listStyleImage = 'url("/static/media/flecheListeDark.28862a44f87a307b3547.svg")';
            });

            localStorage.setItem("mode", "dark");

        }
    }

    useEffect(() => {
        if(localStorage.getItem("mode")==="dark"){
            document.body.style.backgroundColor = "#212f45";
            document.body.style.color = "white";
            document.getElementsByClassName("textNavTel")[0].style.color="white"
            document.getElementsByClassName("navbar")[0].style.backgroundColor="#212f45"
            document.getElementsByClassName("menu")[0].style.backgroundColor="#212f45"

            let burger = document.querySelectorAll(".line");
            burger.forEach(function(link) {
                link.style.backgroundColor = "white";
            });

            let navbarLinks = document.querySelectorAll(".navbar-links a");
            navbarLinks.forEach(function(link) {
                link.style.color = "white";
            });

            let burgerLinks = document.querySelectorAll(".menu a");
            burgerLinks.forEach(function(link) {
                link.style.color = "white";
            });

            setInstaMode(instDark)
            setFaceMode(faceDark)
            setPhoneMode(phoneDark)

            setSrcInsta(instDark)
            setSrcFace(faceDark)
            setSrcPhone(phoneDark)

            setTheme("dark")

            let circle = document.getElementsByClassName("circle")[0]
            circle.style.backgroundImage = `url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjAiIHdpZHRoPSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIj4KICAgIDxwYXRoIGZpbGw9IiNmZmYiCiAgICAgICAgZD0iTTQuMiAyLjVsLS43IDEuOC0xLjguNyAxLjguNy43IDEuOC42LTEuOEw2LjcgNWwtMS45LS43LS42LTEuOHptMTUgOC4zYTYuNyA2LjcgMCAxMS02LjYtNi42IDUuOCA1LjggMCAwMDYuNiA2LjZ6IiAvPgo8L3N2Zz4=)`;
            circle.style.left = `calc(100% - var(--circle-diameter))`;
            circle.style.transition="none"

            let ligneBg = document.querySelectorAll(".ligneCard");
            ligneBg.forEach(function(link) {
                link.style.backgroundColor = "white";
            });

            const elements = document.querySelectorAll('.listeReduc');
            elements.forEach(element => {
                element.style.listStyleImage = 'url("/static/media/flecheListeDark.28862a44f87a307b3547.svg")';
            });


        }else{
            document.body.style.backgroundColor = "white";
            document.getElementsByClassName("textNavTel")[0].style.color="black"
            document.body.style.color = "black";
            document.getElementsByClassName("navbar")[0].style.backgroundColor="white"
            document.getElementsByClassName("menu")[0].style.backgroundColor="white"

            let navbarLinks = document.querySelectorAll(".navbar-links a");
            navbarLinks.forEach(function(link) {
                link.style.color = "black";
            });

            let burgerLinks = document.querySelectorAll(".menu a");
            burgerLinks.forEach(function(link) {
                link.style.color = "black";
            });

            let burger = document.querySelectorAll(".line");
            burger.forEach(function(link) {
                link.style.backgroundColor = "black";
            });

            setInstaMode(icoInsta)
            setFaceMode(icoFacebook)
            setPhoneMode(icoPhone)

            setSrcInsta(icoInsta)
            setSrcFace(icoFacebook)
            setSrcPhone(icoPhone)

            setTheme("white")

            let circle = document.getElementsByClassName("circle")[0]
            circle.style.backgroundImage = `url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjAiIHdpZHRoPSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIj4KICAgIDxwYXRoIGZpbGw9IiNmZmYiCiAgICAgICAgZD0iTTkuMzA1IDEuNjY3VjMuNzVoMS4zODlWMS42NjdoLTEuMzl6bS00LjcwNyAxLjk1bC0uOTgyLjk4Mkw1LjA5IDYuMDcybC45ODItLjk4Mi0xLjQ3My0xLjQ3M3ptMTAuODAyIDBMMTMuOTI3IDUuMDlsLjk4Mi45ODIgMS40NzMtMS40NzMtLjk4Mi0uOTgyek0xMCA1LjEzOWE0Ljg3MiA0Ljg3MiAwIDAwLTQuODYyIDQuODZBNC44NzIgNC44NzIgMCAwMDEwIDE0Ljg2MiA0Ljg3MiA0Ljg3MiAwIDAwMTQuODYgMTAgNC44NzIgNC44NzIgMCAwMDEwIDUuMTM5em0wIDEuMzg5QTMuNDYyIDMuNDYyIDAgMDExMy40NzEgMTBhMy40NjIgMy40NjIgMCAwMS0zLjQ3MyAzLjQ3MkEzLjQ2MiAzLjQ2MiAwIDAxNi41MjcgMTAgMy40NjIgMy40NjIgMCAwMTEwIDYuNTI4ek0xLjY2NSA5LjMwNXYxLjM5aDIuMDgzdi0xLjM5SDEuNjY2em0xNC41ODMgMHYxLjM5aDIuMDg0di0xLjM5aC0yLjA4NHpNNS4wOSAxMy45MjhMMy42MTYgMTUuNGwuOTgyLjk4MiAxLjQ3My0xLjQ3My0uOTgyLS45ODJ6bTkuODIgMGwtLjk4Mi45ODIgMS40NzMgMS40NzMuOTgyLS45ODItMS40NzMtMS40NzN6TTkuMzA1IDE2LjI1djIuMDgzaDEuMzg5VjE2LjI1aC0xLjM5eiIgLz4KPC9zdmc+)`;
            circle.style.left = `0`;

            const elements = document.querySelectorAll('.listeReduc');
            elements.forEach(element => {
                element.style.listStyleImage = 'url("/static/media/flecheListe.bd289e318ebd17167a54.svg")';
            });

            let ligneBg = document.querySelectorAll(".ligneCard");
            ligneBg.forEach(function(link) {
                link.style.backgroundColor = "black";
            });


        }
    }, [setTheme]);



    return (
        <>
            <nav className={`navbar ${show ? 'navbar--show' : 'navbar--hide'}`}>
                    <label className=" buttonDarkMode ui-switch">
                        <input type="checkbox" onChange={changeMode}/>
                        <div className="slider">
                            <div className="circle"></div>
                        </div>
                    </label>

                <div className="fakeDivCenterNav"></div>
                <div className="navbar-links">
                    <div className="linkNav" >
                        <div data-aos="fade-right" data-aos-duration="800" data-aos-once="true">
                            <a href="/" >Accueil</a>
                        </div>
                        <div data-aos="fade-right" data-aos-duration="1000" data-aos-once="true">
                            <a href="/services">Services</a>
                        </div>
                        <div data-aos="fade-right" data-aos-duration="1200" data-aos-once="true">
                            <a href="/realisations" data-aos="fade" data-aos-duration="1200"
                               data-aos-once="true">Réalisations</a>
                               
                        </div>
                    </div>
                    <div className="navbar-logo">
                    <img src={logo} className="imgNavLogo" alt="Logo de l'entreprise" data-aos="fade" data-aos-duration="1400" data-aos-once="true"/>
                    </div>
                    <div className="linkNav">
                        <div data-aos="fade-left" data-aos-duration="800" data-aos-once="true">
                            <a href="/professionnel" >Professionnel</a>
                        </div>
                        <div data-aos="fade-left" data-aos-duration="1000" data-aos-once="true">
                            <a href="/contact" >Obtenir un
                                devis</a>
                        </div>

                    </div>
                </div>
                <div className="navbar-icons">
                    <div className="blocIcoSocial">
                        <a className="linkSocial"
                           onMouseEnter={() => setSrcFace(icoFacebookGreen)}
                           onMouseLeave={() => setSrcFace(faceMode)}
                           href="https://www.facebook.com/profile.php?id=61556932118026" target="_blank"
                           rel="noopener noreferrer">
                            <img className="icoLink" src={srcFace} alt="Icone qui redirige vers Facebook au clique"/>
                        </a>
                        <a className="linkSocial"
                           onMouseEnter={() => setSrcInsta(icoInstaGreen)}
                           onMouseLeave={() => setSrcInsta(instaMode)}
                           href="https://www.instagram.com/alc_paysages/" target="_blank" rel="noopener noreferrer">
                            <img className="icoLink" src={srcInsta} alt="Icone qui redirige vers Instagram au clique"/>
                        </a>
                    </div>
                    <div className="blocIcoSocial">
                        <a className="blocTelNav"
                           onMouseEnter={() => setSrcPhone(icoPhoneGreen)}
                           onMouseLeave={() => setSrcPhone(phoneMode)}
                           href="tel:+0652611391">
                            <img className="icoTel" src={srcPhone} alt="Icone d'un téléphone qui redirige vers un appel à l'entreprise au clique"/>
                            <p className="textNavTel">06 52 61 13 91</p>
                        </a>
                    </div>
                </div>
                <div className="blocBurger">
                    <img src={logo} className="imgNavLogoBurger" alt="Logo de l'entreprise"/>
                    <BurgerButton toggleMenu={toggleMenu} isOpen={menuOpen}/>
                </div>

            </nav>
            <Menu isOpen={menuOpen} closeMenu={closeMenu}/>
        </>
    );
}

export default NavBarre;
