import React, {useEffect, useState} from 'react';
import icoFacebook from "../assets/icons/facebook.svg";
import icoInsta from "../assets/icons/instagram.svg";
import icoMail from "../assets/icons/mail.svg";
import icoInstaGreen from "../assets/icons/instagramGreen.svg";
import icoFacebookGreen from "../assets/icons/facebookGreen.svg";
import icoMailGreen from "../assets/icons/mailGreen.svg";
import {useTheme} from "./ModeContext";

import instDark from "../assets/icons/instagramDark.svg"
import faceDark from "../assets/icons/facebookDark.svg"
import mailDark from "../assets/icons/mailDark.svg"



function FormContact() {
    const [srcInsta, setSrcInsta] = useState(icoInsta);
    const [srcFace, setSrcFace] = useState(icoFacebook);
    const [srcMail, setSrcMail] = useState(icoMail);

    const [instaMode, setInstaMode] = useState(icoInsta);
    const [faceMode, setFaceMode] = useState(icoFacebook);
    const [mailMode, setMailMode] = useState(icoMail);

    const { theme } = useTheme();

    const [name, setName] = useState('');
    const [city, setCity] = useState('');
    const [telephone, setTelephone] = useState('');
    const [message, setMessage] = useState('');
    const [email, setEmail] = useState('');

    const [errors, setErrors] = useState({});

    useEffect(() => {
        if(theme==="dark"){
            setInstaMode(instDark);
            setFaceMode(faceDark);
            setMailMode(mailDark);

            setSrcInsta(instDark);
            setSrcFace(faceDark);
            setSrcMail(mailDark);
        }else{
            setInstaMode(icoInsta);
            setFaceMode(icoFacebook);
            setMailMode(icoMail);

            setSrcInsta(icoInsta);
            setSrcFace(icoFacebook);
            setSrcMail(icoMail);
        }
    }, [theme]);


    const handleChangeName = (e) => {
        setName(e.target.value);
    };
    const handleChangeCity = (e) => {
        setCity(e.target.value);
    };
    const handleChangeTelephone = (e) => {
        setTelephone(e.target.value);
    };
    const handleChangeMessage = (e) => {
        setMessage(e.target.value);
    };
    const handleChangeEmail = (e) => {
        setEmail(e.target.value);
    };

    const validateForm = () => {
        const newErrors = {};

        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        const namePattern = /^[a-zA-Z\s]+$/;
        const cityPattern = /^[a-zA-Z\s]+$/;
        const telephonePattern = /^\+?[0-9\s\-()]{10,15}$/;
        const messagePattern = /.+/;

        if (!emailPattern.test(email)) {
            newErrors.email = 'Format de l\'email invalide';
        }
        if (!namePattern.test(name)) {
            newErrors.name = 'Le nom ne peut contenir que des lettres et des espaces';
        }
        if (!cityPattern.test(city)) {
            newErrors.city = 'La ville ne peut contenir que des lettres et des espaces';
        }
        if (!telephonePattern.test(telephone)) {
            newErrors.telephone = 'Format de téléphone invalide';
        }
        if (!messagePattern.test(message)) {
            newErrors.message = 'Le message ne peut pas être vide';
        }

        setErrors(newErrors);

        // Return true if there are no errors
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmitForm = (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        const formData = new URLSearchParams();
        formData.append("name", name);
        formData.append("email", email);
        formData.append("ville", city);
        formData.append("telephone", telephone);
        formData.append("message", message);

        fetch('/api/mail', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formData.toString(),
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Failed to log in');
                }

            })
            .then(result => {

            })
            .catch(error => {
                console.error('Error:', error);
                alert('An error occurred while logging in');
            });
        alert("Message envoyé avec succès ! Nous vous répondrons dans les plus brefs délais.")
        window.location.reload();
    };


    return (
        <div data-aos="fade" data-aos-duration="1000"  data-aos-once="true" className="blocFormContact" id="devis">
            <div className="blocContactContent" data-aos="fade-right" data-aos-duration="800" data-aos-once="true">
                <div className="blocContentTitle">
                    <h3 className="text-5xl textGreen" >Contactez-nous</h3>
                    <p className="text-sm contactQuerie">Vous avez des questions ou souhaitez obtenir un devis rapide et personnalisé ?<br/><br/>
                         N’hésitez pas à nous contacter directement ou à nous envoyer un e-mail. Notre équipe est à votre écoute et prête à vous fournir des conseils adaptés pour concrétiser tous vos projets d'entretien de jardin.<br/><br/>
                        Chez ALC Paysages, nous sommes là pour vous accompagner à chaque étape, afin de garantir la beauté et la santé de vos espaces extérieurs.</p>
                </div>
                <div className="blocContentSocial">
                    <h3 className="text-xl">Email :</h3>
                    <a className="text-sm" href="mailto:alc.paysages@gmail.com"
                       target="_blank" rel="noopener noreferrer" style={{width:"fit-content"}}><strong>alc.paysages@gmail.com</strong></a>
                </div>
                <div className="blocContentSocial">
                    <h3 className="text-xl">Téléphone :</h3>
                    <a className="text-sm" href="tel:+0652611391"
                       target="_blank" rel="noopener noreferrer" style={{width:"fit-content"}}><strong>06 52 61 13 91</strong></a>
                </div>
                <div className="blocContentSocial">
                    <h3 className="text-xl">Réseaux :</h3>
                    <div className="blocSocialContact">
                        <a className="linkSocial"
                           onMouseEnter={() => setSrcFace(icoFacebookGreen)}
                           onMouseLeave={() => setSrcFace(faceMode)}
                           href="https://www.facebook.com/profile.php?id=61556932118026"
                           target="_blank"
                           rel="noopener noreferrer">
                            <img className="icoLink" src={srcFace} alt="Icone qui redirige vers Facebook au clique"/>
                        </a>
                        <a className="linkSocial"
                           onMouseEnter={() => setSrcInsta(icoInstaGreen)}
                           onMouseLeave={() => setSrcInsta(instaMode)}
                           href="https://www.instagram.com/alc_paysages/" target="_blank"
                           rel="noopener noreferrer">
                            <img className="icoLink" src={srcInsta} alt="Icone qui redirige vers Instagram au clique"/>
                        </a>
                        <a className="linkSocial"
                           onMouseEnter={() => setSrcMail(icoMailGreen)}
                           onMouseLeave={() => setSrcMail(mailMode)}
                           href="mailto:alc.paysages@gmail.com" target="_blank"
                           rel="noopener noreferrer">
                            <img className="icoLink" src={srcMail} alt="Icone qui redirige vers votre boîte mail au clique"/>
                        </a>
                    </div>
                </div>

            </div>
            <form className="blocFormInputContact" onSubmit={handleSubmitForm} data-aos="fade-up" data-aos-duration="800" data-aos-once="true">
                <div className="blocInputName">
                    <p className="text-xs">Nom & Prénom*</p>
                    <input type="text" className="inputLarge" placeholder={'Nom Prénom'} name="name" onChange={handleChangeName}/>
                    {errors.name && <p className="error">{errors.name}</p>}
                </div>
                <div className="blocInputName">
                    <p className="text-xs">Email*</p>
                    <input type="text" className="inputLarge" placeholder={'Email@gmail.com'} name="email" onChange={handleChangeEmail}/>
                    {errors.email && <p className="error">{errors.email}</p>}
                </div>
                <div className="blocInputName">
                    <p className="text-xs">Ville*</p>
                    <input type="text" name="name" className="inputLarge" required={'required'} placeholder={'Ville'} onChange={handleChangeCity}/>
                    {errors.city && <p className="error">{errors.city}</p>}
                </div>
                <div className="blocInputName">
                    <p className="text-xs">Téléphone*</p>
                    <input type="text" name="phone" className="inputLarge" placeholder={'06 01 02 03 04'} onChange={handleChangeTelephone}/>
                    {errors.telephone && <p className="error">{errors.telephone}</p>}
                </div>
                <div className="blocInputMessage">
                    <p className="text-xs">Message*</p>
                    <input type="placeholder" className="inputLarge inputMessage" name="message" placeholder={'Écrivez votre message...'} onChange={handleChangeMessage}/>
                    {errors.message && <p className="error">{errors.message}</p>}
                </div>

                <button className="buttonSendForm">Envoyer</button>

            </form>
        </div>
    );
}

export default FormContact;
