// import React, {useEffect, useState} from 'react';
import React from 'react';
import NavBarre from "../component/NavBarre";

import asset1 from "../assets/img/landingpage/asset1.webp"
import asset2 from "../assets/img/landingpage/asset2.webp"
import asset3 from "../assets/img/landingpage/asset3.webp"
// import asset4 from "../assets/img/landingpage/asset4.webp"
import asset5 from "../assets/img/landingpage/asset5.webp"

// import icoMap from "../assets/icons/mapIco.svg"
// import icoMapDark from "../assets/icons/mapIcoDark.svg"
// import icoMap2 from "../assets/icons/mapIco2.svg"
import icoDraw1 from "../assets/icons/drawIco1.svg"
import icoDraw2 from "../assets/icons/drawIco2.png"

import Footer from "../component/Footer";
import sigleService from "../assets/icons/imageCertif-transformed.png"
import FormContact from "../component/FormContact";

// import 'aos/dist/aos.css';
import ScrollTop from "../component/ScrollTop";
// import { useTheme} from "../component/ModeContext";


function LandingPage() {

    // const { theme } = useTheme();

    // const [src, setSrc] = useState(icoMap);
    // const [mapIco, setMapIco] = useState(icoMap);


    // useEffect(() => {
    //    if(theme === "dark"){
    //        setMapIco(icoMapDark)
    //        setSrc(icoMapDark)
    //    }else{
    //        setMapIco(icoMap)
    //        setSrc(icoMap)
    //    }
    // }, [theme]);

    return (
        <div className="containerPage">
            <NavBarre/>
            <div className="pageContainer">

                <div className="blocPresLanding">
                    <div className="blocBienvenue">
                        <img src={asset1}  data-aos="fade" data-aos-duration="2000" data-aos-once="true"
                             className="imgLanding1" alt="couverture du premier bloque de la page"/>
                        <div data-aos="fade-up" data-aos-once="true" className="blocContentBienvenue">
                            <h1 className="text-3xl"><strong>Valorisez vos espaces verts avec ALC Paysages 🌿</strong></h1> <br />
                            <p className="text-base txtContentLanding">Spécialistes en entretien de parcs et jardins, nous proposons des solutions sur mesure pour les particuliers
                                les professionnels et les copropriétés. Que vous soyez un particulier soucieux de l'entretien de votre jardin
                                un professionnel désireux de créer un environnement agréable pour vos employés ou un syndic cherchant à entretenir les espaces communs de votre copropriété,
                                notre équipe qualifiée répond à vos besoins spécifiques
                                <strong>. Nous assurons un entretien de qualité, adapté à chaque type d'espace extérieur, pour que vos jardins restent beaux et sains toute l'année</strong>.</p>
                                <br />
                            <a href="/#devis">
                                <button className="contact-button text-xs">
                                    Nous contacter
                                </button>
                            </a>
                        </div>
                        <img src={asset1}  data-aos="fade" data-aos-duration="2000" data-aos-once="true"
                             className="imgLanding1Queri" alt="couverture du premier bloque de la page"/>
                    </div>

                    <div className="blocBienvenue">
                        <div data-aos="fade-right" data-aos-once="true" className="blocContentBienvenue">
                            <h2 className="text-2xl textGreen"><strong>Jardinier / Paysagiste à Savigny-Le-Temple</strong></h2>
                            <p className="text-base txtContentLanding">Située à  <strong>Savigny-Le-Temple</strong>, l'entreprise <strong>ALC PAYSAGES</strong> est votre partenaire de confiance pour l'entretien et l'aménagement paysager.
                            Nous intervenons non seulement à Savigny-Le-Temple, mais aussi dans les villes voisines, pour transformer et entretenir vos espaces extérieurs, qu'il s'agisse de jardins privés, 
                            de parcs ou d’espaces communs en copropriété. Grâce à notre expertise, 
                            nous répondons à tous vos besoins en aménagement paysager, en offrant des solutions sur mesure adaptées à chaque type d'espace.</p>
                            {/* <a className="linkMap"
                               onMouseEnter={() => setMapIco(icoMap2)}
                               onMouseLeave={() => setMapIco(src)}
                               href="/services#map"
                               rel="noopener noreferrer">
                                <img className="mapIco" src={mapIco} alt="représente un point gps"/>
                                <p className="text-xs">Map</p>
                            </a> */}
                        </div>
                        <img src={asset2}  data-aos="fade" data-aos-duration="2000"  data-aos-once="true" className="imgLanding1" alt="accompagne et illustre le texte précédent"/>
                    </div>
                </div>

                <div className="blocQuiSomNous">
                    <div data-aos="fade-up-right" className="anirei" data-aos-once="true" data-aos-offset="0" data-aos-anchor-placement="top-bottom" style={{width:"30%"}}>
                        <img src={asset3}  className="img1Qui" alt="parc au format portrait"/>
                    </div>

                    <div className="blocContentQui">
                        <div  className="contentServContent" data-aos-once="true" data-aos="fade-up" data-aos-offset="0" data-aos-anchor-placement="top-bottom">
                            
                            <h3  className="text-3xl quiSommeQuerie"><strong>Qui sommes nous ?</strong></h3>
                            <div  className="blocTextQui">
                                <p>Chez ALC Paysages, nous nous engageons à
                                    <strong> créer</strong> et <strong>entretenir</strong> des espaces extérieurs
                                    exceptionnels pour nos clients</p>
                                <p> Avec expertise et passion pour le jardinage, nous offrons une gamme complète
                                    de services, de la <strong>conception sur mesure</strong> à
                                   <strong> l'entretien régulier</strong> . Transformez votre espace extérieur en un véritable
                                    paradis vert !
                                    <br />
                                    <br />
                                    <strong>Profitez du Crédit d'Impôt pour l'Entretien de votre Jardin</strong>
                                    <br />
                                    Saviez-vous que l'entretien de vos espaces extérieurs peut être éligible à un crédit d'impôt ? En tant que prestataire agréé, ALC Paysages vous permet de bénéficier d'un crédit d'impôt pour les services à la personne pour tous travaux d'entretien de jardin. Vous pouvez ainsi réduire vos frais tout en profitant d'un service de qualité, effectué par des professionnels.
                                    <br />
                                    <br />
                                    Ce crédit d'impôt vous permet de bénéficier de 50% de réduction sur la main-d'œuvre pour les prestations d'entretien de jardin telles que la tonte, le débroussaillage, l'élagage, et bien plus encore. Un moyen simple et efficace de profiter d'un jardin magnifique sans vous soucier des coûts !
                                    </p>
                            </div>
                        </div>
                        {/* <div data-aos="fade-up" data-aos-once="true" style={{width:"100%"}}>
                            <img src={asset4}  className="img2Qui" alt="parc paysage"/>
                        </div> */}
                    </div>
                </div>

                <div className="blocContentMiddle">
                    <div className="blocContentMidText" >
                        <h2 data-aos="fade-up" data-aos-once="true" className="text-2xl textGreen"><strong>Votre Partenaire Idéal pour tous vos besoins
                            en aménagement paysager</strong></h2>
                        <div data-aos="fade-up" data-aos-once="true" className="blocTextQui">
                            <p className="text-base">Chez ALC Paysages, nous nous engageons à préserver et sublimer vos espaces extérieurs avec un service d'entretien professionnel, adapté à chaque besoin. Notre expertise ne se limite pas à l'entretien classique : nous veillons à la santé, la beauté et la longévité de vos jardins, parcs et espaces communs.

                            Nous offrons des prestations de qualité pour les particuliers, les professionnels et les copropriétés, en prenant soin de chaque détail pour garantir des extérieurs toujours impeccables. Grâce à notre équipe passionnée, vos espaces verts resteront beaux et en bonne santé tout au long de l'année.

                            Merci de faire confiance à ALC Paysages, où l'entretien de vos espaces extérieurs est effectué avec professionnalisme et passion. À très bientôt pour entretenir et valoriser vos jardins !</p>
                            <p className="text-base"> Merci de faire confiance à <strong>ALC Paysages</strong>,
                                où la nature prend vie avec passion
                                et professionnalisme. À bientôt dans votre jardin ! </p>
                        </div>
                        <a href="/contact" data-aos="fade-up" data-aos-once="true">
                            <button   className="contact-button text-xs">
                                Nous contacter
                            </button>
                        </a>
                    </div>

                    <img src={icoDraw1} className="img1Draw" alt="paysagiste"
                         data-aos="fade-left" data-aos-duration="800" data-aos-once="true"
                    />
                </div>

            </div>

            <img src={asset5} className="imgLonguePage" alt="jardin entretenu"/>

            <div className="pageContainer">
                <div className="blocReduc">
                    <img data-aos="fade-up" data-aos-once="true" src={sigleService} className="imgSigleServe" alt="logo '-50% de réduction d'impôt'"/>
                    <h3 data-aos="fade-up" data-aos-once="true" className="text-3xl">
                    <strong>Nouveauté chez ALC Paysages ! </strong>
                    </h3>
                    <p data-aos="fade-up" data-aos-once="true" className="text-base">
                    Profitez dès maintenant d'une réduction de 50% sur nos services d'entretien de jardins grâce à notre agrément Service à la Personne. Cette offre inclut une remise immédiate ou un crédit d’impôt sur la main-d'œuvre pour tous vos travaux d'entretien de jardin, vous permettant ainsi de bénéficier de prestations de qualité à prix réduit.
                    Chez ALC Paysages, nous nous engageons à entretenir vos espaces extérieurs avec professionnalisme et passion, tout en vous offrant des solutions avantageuses.
                    </p><br/>
                    <p data-aos="fade-up" data-aos-once="true" className="text-base">
                        Liste des travaux de petit jardinage éligibles au <strong>50% de crédit d'impôt</strong>  :
                    </p>
                    <div  className="blocListeReduc">
                        <div className="blocImageReduc">
                            <img data-aos="fade-up" data-aos-once="true" src={icoDraw2} className="icoDrawReduc" alt="remboursement"/>
                        </div>
                        <div className="blocListeReducContent">
                            <ul className="listeReduc">
                                <li data-aos="fade-up" data-aos-once="true">La tonte</li>
                                <li data-aos="fade-up" data-aos-once="true">Le débroussaillage</li>
                                <li data-aos="fade-up" data-aos-once="true">La taille d'arbres et d'arbustes hors élagage</li>
                                <li data-aos="fade-up" data-aos-once="true">Le ramassage des feuilles</li>
                                <li data-aos="fade-up" data-aos-once="true">Le désherbage</li>
                                <li data-aos="fade-up" data-aos-once="true">Le bêchage, le binage et le griffage</li>
                                <li data-aos="fade-up" data-aos-once="true">Le petit arrachage manuel et évacuation des végétaux</li>
                                <li data-aos="fade-up" data-aos-once="true">L'entretien des massifs</li>
                                <li data-aos="fade-up" data-aos-once="true">La petite maintenance régulière des allées et des terrasses</li>
                                <li data-aos="fade-up" data-aos-once="true">La taille des haies, des fruitiers, des rosiers</li>
                            </ul>
                        </div>

                    </div>
                    <p data-aos="fade-up" data-aos-once="true" className="text-base" style={{fontStyle:"italic"}}>L'avantage fiscal est toutefois limité à des dépenses d'entretien des jardins
                        maximales de 5 000 € par an et par foyer fiscal.</p>
                    <a href="https://www.servicesalapersonne.gouv.fr/beneficier-des-sap/services-la-personne-avantages-fiscaux-et-sociaux-pour-particuliers" target="_blank" rel="noopener noreferrer" style={{marginTop:"1vw"}}>
                        <button data-aos="fade-up" data-aos-once="true" className="contact-button text-xs">
                            En savoir plus
                        </button>
                    </a>
                </div>


                <FormContact />
            </div>

            <ScrollTop/>
            <Footer/>
        </div>
    );
}

export default LandingPage;
