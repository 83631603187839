import React from 'react';
import NavBarre from "../component/NavBarre";
import Footer from "../component/Footer";

import asset1 from "../assets/img/servicepage/asset1.webp";
import asset2 from "../assets/img/servicepage/asset2.webp";
import asset3 from "../assets/img/servicepage/asset3.webp";
// import asset4 from "../assets/img/servicepage/asset4.webp";
import asset5 from "../assets/img/zones-intervention-white.png";

// import icoMap from "../assets/icons/mapIco2.svg"
// import MapComponent from "../component/MapComponent";
import AccordionComponent from "../component/Accordeon";
import logo from "../assets/icons/logo.png"
import ScrollTop from "../component/ScrollTop";


function ServicePage() {


    return (
        <div className="containerPage">
            <NavBarre/>
            <div className="pageContainer">
                <div className="bloc1Service">
                    <img src={asset1} data-aos="fade-right" data-aos-once="true" className="img1QuiBis" alt="Jardin paysager" />

                    <div data-aos="fade-up" data-aos-once="true" className="blocContentQuiService">
                        <h3 className="text-3xl textGreen"><strong>ALC Paysages - Entretien Parc & Jardin</strong></h3>

                        <p>Confiez à ALC Paysages l'entretien expert de vos espaces verts pour préserver la beauté et la vitalité de vos jardins. Notre équipe dévouée met son savoir-faire au service de
                            votre verdure luxuriante et de l'esthétique impeccable de vos extérieurs. Chaque intervention est personnalisée pour répondre à vos besoins spécifiques, 
                            assurant un entretien soigné et un environnement extérieur toujours à son meilleur.</p>

                        <a href="/contact">
                            <button className="contact-button text-xs">
                                Nous contacter
                            </button>
                        </a>
                    </div>
                    <img src={asset1} data-aos="fade-right" data-aos-once="true" className="img1QuiBisQuery" alt="jardin illustre le texte"/>
                </div>

                <div className="bloc2Service">
                    <div className="blocContentServ">

                        <div data-aos="fade-up" data-aos-once="true" className="blocContentQuiServiceElagage" >
                        <div className="blocTextQui">
                        <h3 className="text-3xl textGreen"><strong>ALC Paysages -Élagage d'Arbres</strong></h3>
                        
                        <p>Confiez à ALC Paysages l'élagage de vos arbres pour préserver leur vitalité, leur santé et leur esthétique. Grâce à notre expertise soignée et à notre savoir-faire, nous effectuons des interventions qui garantissent la forme optimale de chaque arbre tout en favorisant sa croissance durable. Nous créons ainsi un environnement extérieur harmonieux, où la nature s'épanouit avec élégance et sécurité.</p>
                            </div>
                        <a href="/contact">
                            <button className="contact-button text-xs">
                                Nous contacter
                            </button>
                        </a>
                    </div>


                    </div>
                    <img src={asset2} data-aos="fade-up" data-aos-once="true" className="imgLongueService" alt="illustre l'élagage d'un arbre"/>
                </div>

                <div className="bloc1Service">
                    <img src={asset3} data-aos="fade-right" data-aos-once="true" className="img1QuiBis" alt="Jardin paysager" />

                    <div data-aos="fade-up" data-aos-once="true" className="blocContentQuiService">
                        <h3 className="text-3xl textGreen"><strong>ALC Paysages - Tonte & Débroussaillages</strong></h3>

                        <p>Offrez à votre pelouse le soin professionnel qu'elle mérite avec ALC Paysages. Nous nous engageons à réaliser une tonte précise et régulière, une fertilisation 
                            équilibrée et une gestion attentive de votre terrain. Le résultat ?
                             Une verdure luxuriante qui transforme votre jardin en un véritable espace de détente, où chaque coin reflète l'harmonie et la beauté.</p>

                        <a href="/contact">
                            <button className="contact-button text-xs">
                                Nous contacter
                            </button>
                        </a>
                    </div>
                    <img src={asset3} data-aos="fade-right" data-aos-once="true" className="img1QuiBisQuery" alt="jardin illustre le texte"/>
                </div>
                <br />
                <br />
                
            
            </div>
            
            <div className="blocTitleMap"id="map">
                {/* <img src={icoMap} className="mapIco" alt="Icône d'un point gps"/>
                <h3 className="text-3xl textGreen"><strong>Zones déservie</strong></h3> */}
            </div>
            <div className="blocMap" >
                <div className="blocMapPrinc">
                    <img src={asset5} className="mapComponentBloc" alt="illustre une tonte de pelouse"/>
                    {/* <div className="mapComponentBloc">
                        <MapComponent/>
                    </div> */}

                    <div className="contentMapAcco">
                        <AccordionComponent/>
                        <div data-aos="fade-up" data-aos-once="true" className="blocFinContact">
                            <img src={logo} className="logoFin" alt="Logo de l'entreprise"/>
                            <a href="/contact">
                                <button className="contact-button text-xs">
                                    Nous contacter
                                </button>
                            </a>
                        </div>
                    </div>
                </div>

            </div>
            
            <ScrollTop/>
            <Footer/>
        </div>
    );
}

export default ServicePage;
