import React from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';

function AccordionComponent() {


    return (
        <div className="blocAcco">
            <AccordionSummary>
                <Typography className="accordion-typography">Intervention en Seine et Marne</Typography>
            </AccordionSummary>
            <div className="ligneCard"></div>
            <AccordionSummary>
                <Typography className="accordion-typography">Intervention en Essone</Typography>
            </AccordionSummary>
            <div className="ligneCard"></div>
            <AccordionSummary>
                <Typography className="accordion-typography">Intervention en Val de marne</Typography>
            </AccordionSummary>
            <div className="ligneCard"></div>
            <br />
            <AccordionSummary>
                <Typography className="accordion-typography">Nous étudions toutes demandes hors zones desservies</Typography>
            </AccordionSummary>
        </div>
    );
}

export default AccordionComponent;
